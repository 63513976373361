import React, { Fragment } from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import { motion, useViewportScroll, useTransform } from "framer-motion"
import { useRefScrollProgress } from "../../hooks/UseRefScrollProgress"
import { Section, MotionContainer, Overlay, Title } from "./styles"

const Splash = ({ title, year, image, color }) => {
  const { ref, start, end } = useRefScrollProgress()
  const { scrollYProgress } = useViewportScroll()
  const scale = useTransform(scrollYProgress, [start, end + 0.2], [1.2, 1])
  const opacity = useTransform(scrollYProgress, [start, end + 0.2], [1, 0])
  const translateY = useTransform(
    scrollYProgress,
    [start, end + 0.2],
    ["0", "-100px"]
  )

  // Render title
  const renderTitle = () => {
    if (!year) return <Title>{title}</Title>
    return (
      <Fragment>
        <Title>{title}</Title>
        <Title>{year}</Title>
      </Fragment>
    )
  }

  return (
    <Section ref={ref} color={color}>
      <motion.div animate={{ scale: 1.2 }} transition={{ duration: 2 }}>
        <MotionContainer style={{ scale, translateY, opacity }}>
          <Img
            fluid={image.childImageSharp.fluid}
            alt="splash"
            style={{ position: "inherit" }}
          />
        </MotionContainer>
      </motion.div>
      <Overlay center={year !== undefined}>
        <motion.div style={{ scale, opacity }}>{renderTitle()}</motion.div>
      </Overlay>
    </Section>
  )
}

Splash.propTypes = {
  title: PropTypes.string,
  image: PropTypes.object,
}

export default Splash
