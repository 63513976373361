import styled from "styled-components"
import { motion } from "framer-motion"
import { breakpoints } from "../../../utils/styles"

export const Section = styled.div`
  background: ${({ theme, color }) =>
    color || theme.color.backgroundSectionOne};
  height: 70vh;
  overflow: hidden;
  position: relative;

  @media (max-width: ${breakpoints.xl}px) {
    height: 700px;
  }

  @media (max-width: ${breakpoints.l}px) {
    height: 400px;
  }
`

export const MotionContainer = styled(motion.div)`
  height: 80vh;

  @media (max-width: ${breakpoints.xl}px) {
    height: 800px;
  }

  @media (max-width: ${breakpoints.l}px) {
    height: 500px;
  }
`

export const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.1);
  color: ${({ theme }) => theme.color.white};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: ${({ center }) => (center ? "center" : "flex-end")};
  padding: 64px 32px;

  @media (max-width: ${breakpoints.l}px) {
    padding: 48px;
  }
`

export const Title = styled.h1`
  text-align: center;
  font-size: 56px;
  max-width: 800px;
  padding: 8px;
  margin: 0;

  @media (max-width: ${breakpoints.l}px) {
    font-size: 32px;
  }
`
