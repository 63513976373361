import styled from "styled-components"
import Img from "gatsby-image"
import { breakpoints } from "../../../utils/styles"

export const Section = styled.div`
  background: ${({ theme, color }) =>
    color ? color : theme.color.backgroundSectionOne};
  padding: 64px 32px;

  @media (max-width: ${breakpoints.l}px) {
    padding: 48px 24px;
  }
`

export const Content = styled.div`
  color: ${({ theme, color }) => (color ? color : theme.color.black)};
  max-width: 900px;
  min-height: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: ${({ leftAlign }) => (leftAlign ? "left" : "center")};

  @media (max-width: ${breakpoints.l}px) {
    min-height: 400px;
  }
`

export const Image = styled(Img)`
  width: ${({ large }) => (large ? "500px" : "300px")};
  margin-bottom: 48px;

  @media (max-width: ${breakpoints.l}px) {
    width: 200px;
  }
`

export const Description = styled.p`
  font-size: 24px;
  line-height: 32px;

  @media (max-width: ${breakpoints.l}px) {
    font-size: 16px;
    line-height: 24px;
  }
`
