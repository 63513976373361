import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import Swiper from "react-id-swiper"
import { useIntl } from "gatsby-plugin-intl"
import { navigate, useStaticQuery, graphql } from "gatsby"
import "swiper/css/swiper.css"
import Heading, { Type } from "../../UI/Heading"

import { Section, Content, SwipeWrapper, Card, CardTitle } from "./styles"

const ConflictCards = ({ title }) => {
  const data = useStaticQuery(graphql`
    query ConflictCardQuery {
      allMarkdownRemark(filter: { frontmatter: { type: { eq: "conflict" } } }) {
        nodes {
          frontmatter {
            slug
            lang
            title
            id
          }
          id
        }
      }
      allFile(filter: { relativeDirectory: { eq: "cards" } }) {
        nodes {
          id
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      site {
        siteMetadata {
          languages {
            en
            sv
          }
        }
      }
    }
  `)
  const { locale } = useIntl()
  const { languages } = data.site.siteMetadata

  const conflictData = data.allMarkdownRemark.nodes.filter(
    ({ frontmatter: { lang } }) => lang === languages[locale]
  )

  // Conflicts
  const conflicts = conflictData.map(
    ({ frontmatter: { title, slug, id } }) => ({
      title,
      slug,
      image: data.allFile.nodes.find(image => image.name === id),
    })
  )

  // Swipe params
  const params = {
    effect: "coverflow",
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: "auto",
    coverflowEffect: {
      rotate: 0,
      stretch: 0,
      depth: 300,
      modifier: 1,
      slideShadows: true,
    },
    pagination: {
      el: ".swiper-pagination",
    },
  }

  return (
    <Section>
      <Content>
        <Heading type={Type.h2}>{title}</Heading>
        <SwipeWrapper>
          <Swiper {...params}>
            {conflicts.map((item, i) => (
              <Card key={i} onClick={() => navigate(`/${locale}${item.slug}`)}>
                <CardTitle>{item.title}</CardTitle>
                <Img
                  fluid={item.image.childImageSharp.fluid}
                  alt={item.image.name}
                  style={{ position: "inherit" }}
                />
              </Card>
            ))}
          </Swiper>
        </SwipeWrapper>
      </Content>
    </Section>
  )
}

ConflictCards.propTypes = {
  title: PropTypes.string,
  conflicts: PropTypes.array,
}

export default ConflictCards
