import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import { useViewportScroll, useTransform } from "framer-motion"
import { useRefScrollProgress } from "../../hooks/UseRefScrollProgress"
import { Section, MotionContainer, Overlay } from "./styles"

const CoverImage = ({ image }) => {
  const { ref, start, end } = useRefScrollProgress()
  const { scrollYProgress } = useViewportScroll()
  const scale = useTransform(scrollYProgress, [start, end + 0.2], [1.2, 1])
  const translateY = useTransform(
    scrollYProgress,
    [start, end + 0.2],
    ["0", "-100px"]
  )
  return (
    <Section ref={ref}>
      <MotionContainer style={{ scale, translateY }}>
        <Img
          fluid={image.childImageSharp.fluid}
          alt={image.name}
          style={{ position: "inherit" }}
        />
      </MotionContainer>
      <Overlay />
    </Section>
  )
}

CoverImage.propTypes = {
  image: PropTypes.object,
}

export default CoverImage
