import React from "react"
import PropTypes from "prop-types"
import ReactHtmlParser from "react-html-parser"

import Heading, { Type } from "../../UI/Heading"
import Button from "../../UI/Button"

import { Section, Content, Image, Description } from "./styles"

// Render Image
const renderImage = (image, large) => {
  if (!image) return null
  return (
    <Image fluid={image.childImageSharp.fluid} alt={image.name} large={large} />
  )
}

// Render button
const renderButton = (onClick, text) => {
  if (!text || !onClick) return null
  return <Button onClick={onClick}>{text}</Button>
}

const TextImage = ({
  title,
  description,
  image,
  large,
  backgroundColor,
  textColor,
  buttonText,
  onClick,
  leftAlign,
}) => {
  return (
    <Section color={backgroundColor}>
      <Content color={textColor} leftAlign={leftAlign}>
        {renderImage(image, large)}
        <Heading type={Type.h2}>{title}</Heading>
        <Description>{ReactHtmlParser(description)}</Description>
        {renderButton(onClick, buttonText)}
      </Content>
    </Section>
  )
}

TextImage.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.object,
}

export default TextImage
