import styled from "styled-components"
import Img from "gatsby-image"
import { breakpoints } from "../../../utils/styles"

export const Section = styled.div`
  overflow-x: hidden;
  background: ${({ theme }) => theme.color.white};
  padding: 64px 0;

  @media (max-width: ${breakpoints.l}px) {
    padding: 48px 0;
  }
`

export const Content = styled.div`
  min-height: 600px;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: ${breakpoints.l}px) {
    min-height: 400px;
  }
`

export const SwipeWrapper = styled.div`
  display: flex;
  margin: 24px;
`

export const Card = styled.div`
  width: 600px;
  height: 400px;
  border-radius: 12px;
  overflow: hidden;
  position: relative;

  @media (max-width: ${breakpoints.m}px) {
    width: 540px;
    height: 360px;
  }

  @media (max-width: ${breakpoints.s}px) {
    width: 320px;
    height: 220px;
  }
`

export const CardTitle = styled.h3`
  position: absolute;
  color: ${({ theme }) => theme.color.white};
  font-weight: 400;
  z-index: 1;
  right: 24px;
  bottom: 8px;

  @media (max-width: ${breakpoints.l}px) {
    text-align: center;
    right: 8px;
    left: 8px;
    bottom: 8px;
  }
`

export const Image = styled(Img)`
  width: 300px;
  margin-bottom: 48px;

  @media (max-width: ${breakpoints.l}px) {
    width: 200px;
  }
`

export const Title = styled.h1`
  font-size: 32px;
  max-width: 650px;
  margin: 0;

  @media (max-width: ${breakpoints.l}px) {
    font-size: 24px;
  }
`

export const Description = styled.p`
  text-align: center;
  font-size: 24px;
  line-height: 32px;

  @media (max-width: ${breakpoints.l}px) {
    font-size: 16px;
    line-height: 24px;
  }
`
